<template>
    <div class="pt-3">
        <Link :href="route('newsportal.pharmacy')">
            <img :src="FarmakiaBanner" :srcset="FarmakiaBannerWebp + ', ' + FarmakiaBanner" alt="Φαρμακεία Εφημερεύοντα" class="rounded-corners" width="340">
        </Link>
        <a class="hidden" :href="route('newsportal.pharmacy')">
            διανυκτερεύοντα φαρμακεία λευκωσία
        </a>

        <a class="hidden" :href="route('newsportal.pharmacy')">
            διανυκτερευοντα φαρμακεια λεμεσοσ
        </a>

        <a class="hidden" :href="route('newsportal.pharmacy')">
            εφημερευον φαρμακεια
        </a>

        <a class="hidden" :href="route('newsportal.pharmacy')">
            dianikterevon farmakia
        </a>

    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';
import FarmakiaBanner from '/resources/images/cyprusnews/farmakia-banner.jpg'
import FarmakiaBannerWebp from '/resources/images/cyprusnews/farmakia-banner.webp'

export default {
    components : {
        Link,
    },
    data() {
        return {
            FarmakiaBanner,
            FarmakiaBannerWebp,
        }
    },
}
</script>

<style scoped>
.rounded-corners {
    border-radius : 8px;
}
</style>
