<template>
    <Head :title="__('CyprusNews.Live: Latest News from Cyprus')"/>
    <Navbar/>
    <PushModal/>
    <div class="section">
        <div class="container">
            <div class="grid">
                <CustomWeatherWidget v-if="weather"
                                     :weather="weather"
                                     class="col-12 md:col-6"/>

                <PharmacyWidget class="col-12 md:col-6 flex justify-content-center" v-if="$page.props.locale === 'el'"/>
            </div>
        </div>
        <div class="pt-4 container">
            <div v-if="$page.props.referral === '404'">
                <Message severity="info">{{ __('The article was deleted or is no longer available.') }}</Message>
            </div>
            <h2>{{__('Latest News')}}</h2>
            <div class="grid">
                <template v-for="(article, index) in allArticles" :key="article.id">
<!--                    <AdComponent v-if="showAdvert(index)"/>-->
                    <div v-if="showNewsletter(index)" class="surface-section col-12 lg:col-4 md:col-6">
                        <SubscribeNewsletter/>
                    </div>
                    <div class="col-12 lg:col-4 md:col-6">
                        <Article
                            :article="article"
                            :newspaper="article.newspaper"
                        />
                    </div>
                </template>
                <div ref="loadMoreIntersect"></div>
            </div>
        </div>
        <ScrollTop/>
    </div>
    <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" position="bottom-right"/>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import Footer from "./Partials/Footer.vue";
import Navbar from "./Partials/Navbar.vue";
import {Head} from "@inertiajs/vue3";
import Article from "@/Components/NewsPortal/Article.vue";
import ScrollTop from 'primevue/scrolltop';
import InfiniteScrollMixin from '../../Components/Mixins/InfiniteScrollMixin.vue';
import PushModal from '@/Components/Modals/PushModal.vue';
import WeatherWidget from '@/Components/WeatherWidget.vue';
import AdComponent from '@/Components/AdComponent.vue';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import CustomWeatherWidget from '@/Components/CustomWeatherWidget.vue';
import PharmacyWidget from '@/Components/PharmacyWidget.vue';
import SubscribeNewsletter from '@/Components/SubscribeNewsletter.vue';

export default {
    components : {
        SubscribeNewsletter,
        Link,
        PharmacyWidget,
        AdComponent,
        PushModal,
        Head,
        Footer,
        Navbar,
        Article,
        ScrollTop,
        WeatherWidget,
        Message,
        Toast,
        CustomWeatherWidget,
    },
    props  : {
        articles : Object,
        weather  : Object,
    },
    mixins : [InfiniteScrollMixin],
    mounted() {
        if (this.$page.props.message && localStorage.getItem('message_fingerprint') !== this.$page.props.message.fingerprint) {
            this.$toast.add({severity : this.$page.props.message.type, detail : this.$page.props.message.message, life : 3000});
            localStorage.setItem('message_fingerprint', this.$page.props.message.fingerprint)
        }

//        const script = document.createElement('script');
//        script.setAttribute('src', 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2609724905403481');
//        script.setAttribute('crossorigin', 'anonymous');
//        script.setAttribute('defer', '');
//        document.head.appendChild(script);
//
//        const script2 = document.createElement('script');
//        script2.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-D48WVM05V8');
//        script2.setAttribute('defer', '');
//        document.head.appendChild(script2);
    },
    methods : {
        showNewsletter(index) {
            return index === 4;
        },
        showAdvert(index) {
            index = index > 4 ? index + 1 : index;

            return (index > 0 && index % 12 === 0) || index === 6;
        }
    }
};
</script>


