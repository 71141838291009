<template>
<!--  <div class="weather-icon">☀️</div> &lt;!&ndash; clear day &ndash;&gt;-->
<!--  <div class="weather-icon">🌤</div> &lt;!&ndash; few clouds day &ndash;&gt;-->
<!--  <div class="weather-icon">⛅</div> &lt;!&ndash; partly cloudy day &ndash;&gt;-->
<!--  <div class="weather-icon">🌥</div> &lt;!&ndash; mostly cloudy day &ndash;&gt;-->
<!--  <div class="weather-icon">🌦</div> &lt;!&ndash; sun behind rain cloud &ndash;&gt;-->
<!--  <div class="weather-icon">🌧</div> &lt;!&ndash; cloud with rain &ndash;&gt;-->
<!--  <div class="weather-icon">🌨</div> &lt;!&ndash; cloud with snow &ndash;&gt;-->
<!--  <div class="weather-icon">🌩</div> &lt;!&ndash; cloud with lightning &ndash;&gt;-->
<!--  <div class="weather-icon">⛈</div> &lt;!&ndash; cloud with lightning and rain &ndash;&gt;-->
<!--  <div class="weather-icon">🌪</div> &lt;!&ndash; tornado &ndash;&gt;-->
<!--  <div class="weather-icon">🌫</div> &lt;!&ndash; foggy &ndash;&gt;-->
<!--  <div class="weather-icon">☁️</div> &lt;!&ndash; cloudy &ndash;&gt;-->
<!--  <div class="weather-icon">❄️</div> &lt;!&ndash; snowflake &ndash;&gt;-->
<!--  <div class="weather-icon">🔥</div> &lt;!&ndash; hot &ndash;&gt;-->
<!--  <div class="weather-icon">💨</div> &lt;!&ndash; windy &ndash;&gt;-->
    <div class="weather-widget">
        <div v-for="day in weather" :key="day.dayOfWeek" class="weather-day">
            <div class="weather-description">{{ day.dayOfWeek }}</div>
            <div :title="day.description" class="weather-icon">{{ getIcon(day.description) }}</div>
            <div class="temperature">{{ day.temp }}°C</div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3';

export default {
    components : {Link},
  props: {
    weather: {
      type: Object,
      required: true
    }
  },
  methods: {
    getIcon(description) {
      if (description === 'clear sky') {
        return '☀️';
      } else if (description === 'few clouds') {
        return '🌤';
      } else if (description.includes('clouds')) {
        return '☁️';
      } else if (description.includes('rain')) {
        return '🌧';
      } else if (description.includes('thunderstorm')) {
        return '⛈';
      } else if (description.includes('snow')) {
        return '❄️';
      } else if (description.includes('mist')) {
        return '🌫';
      } else {
        return '☁️';
      }
    }
  },
}
</script>

<style scoped>
.weather-widget {
  display: flex;
  justify-content: center; /* This line centers the child elements */
  background-color: var(--blue-800);
  color: white;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  padding: 3px; /* Added padding for aesthetic spacing */
  margin: auto;
  max-width: 340px; /* Set your desired maximum width here */
}
.weather-day {
  display: flex;
  flex-direction: column;
  justify-content: center; /* This centers the content vertically */
  align-items: center; /* This centers the content horizontally */
  padding: 10px;
  text-align: center;
  border-right: 1px solid #34495e;
}
.weather-day:last-child {
  border-right: none;
}
.weather-icon {
  font-size: 24px;
  height: 35px;
}
.temperature {
  font-size: 18px;
  font-weight: bold;
}
.weather-description {
  font-family: 'Arial', sans-serif; /* A common, readable font */
  letter-spacing: 0.05em; /* Adjust letter spacing */
  line-height: 1.4em; /* Adjust line height */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Optional text shadow for depth */
}
</style>
